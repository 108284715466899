import React, { useEffect, useState } from 'react';
import { useNavigate, Link,useLocation } from 'react-router-dom';
import { Navbar, Nav, Col, Container } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBack from '@mui/icons-material/ArrowBack';
import List from '@mui/icons-material/List';
import SearchForm from './SearchForm';
import LoginIcon from '@mui/icons-material/Login';
import { googleLogout } from '@react-oauth/google';
import useRedirectToLogin from '../hooks/useRedirectToLogin';

googleLogout();

function Header() {
  const [isWebView, setIsWebView] = useState(false);
  const redirectToLogin = useRedirectToLogin();
  const token = localStorage.getItem('aircheck_access');
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the query parameter 'isWebView' is true
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const isWebViewParam = query.get('wv');
    setIsWebView(isWebViewParam === 'true/');
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const handleLogin = () => {
    redirectToLogin();
  };

  const handleLogout = () => {
    localStorage.removeItem('aircheck_access');
    localStorage.removeItem('aircheck_refresh');
    googleLogout();
    navigate('/login');
  };



  if (isWebView) {
    // If `isWebView` is true, display only the centered search form
    return (
      <Container className="d-flex justify-content-center align-items-center mt-4 mb-2"  >
        <SearchForm />
      </Container>
    );
  }

  return (
    <Container>
      <Navbar expand="lg">
        <Col className="hide-on-small-screen">
          <Navbar.Brand onClick={() => navigate('/')}>
            <Link className="custom-link">
              <>TV <span id='logo'> Show Availability </span></>
            </Link>
          </Navbar.Brand>
        </Col>

        <Col xs={3} md={3} lg={5}>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className='float-right' />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <MenuItem onClick={goBack}>
                <IconButton color="inherit">
                  <ArrowBack />
                </IconButton>
                <span className="d-none d-sm-block">back</span>
              </MenuItem>
              {token ? (
                <>
                  <MenuItem onClick={() => navigate('/watchlist')}>
                    <IconButton color="inherit">
                      <List />
                    </IconButton>
                    <span className="d-none d-sm-block">watchlist</span>
                  </MenuItem>

                  <MenuItem onClick={() => navigate('/reminder')}>
                    <IconButton color="inherit">
                      <NotificationsIcon />
                    </IconButton>
                    <span className="d-none d-sm-block">Reminder</span>
                  </MenuItem>

                  <MenuItem onClick={handleLogout}>
                    <IconButton color="inherit">
                      <LogoutIcon />
                    </IconButton>
                    <span className="d-none d-sm-block">logout</span>
                  </MenuItem>
                </>
              ) : (
                <MenuItem onClick={handleLogin}>
                  <IconButton size="small" aria-haspopup="true" color="inherit">
                    <LoginIcon />
                  </IconButton>
                  <span className="d-none d-sm-block">login</span>
                </MenuItem>
              )}
            </Nav>
          </Navbar.Collapse>
        </Col>
      </Navbar>
      <SearchForm />
    </Container>
  );
}

export default Header;
