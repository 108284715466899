import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Button, Accordion, Col, Row, Modal, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import { useLazySearchEpisodePromosQuery, } from '../actions/searchTrailer';

import 'react-toastify/dist/ReactToastify.css';
import useRedirectToLogin from '../hooks/useRedirectToLogin';
import { useAddReminderMutation, useEpisodeAvailabilityMutation } from '../actions/apiSlice';
const placeholderImageUrl = 'https://via.placeholder.com/600x400.png?text=Poster+Not+Available';

const EpisodeAvailability = ({ show, resetForm }) => {
  const redirectToLogin = useRedirectToLogin();
  const token = localStorage.getItem('aircheck_access');
  const [searchParams] = useSearchParams();
  const tid = searchParams.get('tid');

  const [seasonNumber, setSeasonNumber] = useState('');
  const [episodeNumber, setEpisodeNumber] = useState('');
  const [availabilityMessage, setAvailabilityMessage] = useState('');
  const [maxEpisode, setMaxEpisode] = useState('');
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [isFirstClick, setIsFirstClick] = useState(true);
  const [isAccordionStop, setIsAccordionStop] = useState(false);
  const [videos, setVideos] = useState([]);


  const [triggersearchEpisodePromos, { data: trailerData, isLoading: trailerLoading, error: trailerError }] = useLazySearchEpisodePromosQuery();


  useEffect(() => {
    if (trailerData) {
      setVideos(trailerData.items);
    }
  }, [trailerData]);






  const handleAccordionClick = () => {
    if (seasonNumber && episodeNumber && isFirstClick) {
      triggersearchEpisodePromos({ query: show.title, seasonNumber: seasonNumber, episodeNumber: episodeNumber });
      setIsFirstClick(false);
    }
    setIsAccordionStop(!isAccordionStop);

  };



  const [isInfoVisible, setIsInfoVisible] = useState(true); // true for "Episode Info", false for "Episode Promo"


  const toggleView = () => {
    setIsInfoVisible((prev) => !prev);
  };

  const [airDate, setAirDate] = useState('');
  const [responseData, setResponseData] = useState('');
  const [formattedAirDate, setFormattedAirDate] = useState('');
  const currentDate = new Date().getTime();
  const [isHovered, setIsHovered] = useState(false);
  const [addSetReminder, { isSuccess: reminderSuccess, isError: isReminderError, error: reminderError }] = useAddReminderMutation();
  const [checkForEpisode, { isLoading: episodeLoad, data: availabilityResponse }] = useEpisodeAvailabilityMutation();

  const navigate = useNavigate();
  const hasShownReminderToast = useRef(false);

  useEffect(() => {
    if (resetForm) {
      setSeasonNumber('');
      setEpisodeNumber('');
    }
    if (isReminderError) {
      if (reminderError.data.non_field_errors && reminderError.data.non_field_errors.includes("A reminder for this episode already set!!")) {
        navigate('/reminder')
      }
      // Handle other errors
      toast.error(`${Object.values(reminderError.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });

    }

    if (reminderSuccess && !hasShownReminderToast.current) {
      toast.success('Reminder set!!', {
        position: toast.POSITION.BOTTOM_CENTER,
        onClick: () => {
          navigate('/reminder');
        },
      });
      hasShownReminderToast.current = true; // Mark as shown
    }
  }, [resetForm, reminderError, reminderSuccess, isReminderError, navigate, redirectToLogin]);

  useEffect(() => {
    if (availabilityResponse) {

    }
  }, [availabilityResponse, currentDate]);

  if (!show || !show.title) {
    return null;
  }

  const checkEpisodeAvailability = async (season, episode) => {
    const payload = {
      season: season,
      episode: episode,
      title: show.title,
      kind: show.kind,
      title_id: show.imdbID,
      tmdb_id: tid,
    }
    try {
      const response = await checkForEpisode(payload).unwrap();

      const message = response.message;
      const airDate = new Date(response.airDate).getTime();

      setAvailabilityMessage(message);
      setAirDate(airDate);
      setFormattedAirDate(response.airDate);
      setResponseData(response);
      setMaxEpisode(response["extra_data"]?.["total_episodes"]);
      setIsFirstClick(true);



      const containsNot = message.includes('not');

      if (containsNot) {
        toast.warn(message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: true,
        });
      } else {
        toast.success(message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: true,
        });
      }

      if (airDate >= currentDate) {
        setShowReminderModal(true); // Open the reminder modal
      }
    } catch (error) {
      console.error('Failed to check episode availability:', error);
    }
  };

  const setReminder = async () => {
    const token = localStorage.getItem('aircheck_access');
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }

    const backendDate = formatDate(formattedAirDate);

    if (!token) {
      toast.error('Please Log in', {
        position: toast.POSITION.TOP_CENTER,
      });
      redirectToLogin();

      return;
    }

    const payload = {
      set_reminder: true,
      air_date: backendDate,
      temp_data: show,
      season: seasonNumber,
      episode: episodeNumber,
    }

    addSetReminder(payload);
  }

  const handleCheckAvailability = (e) => {
    e.preventDefault();
    if (seasonNumber && episodeNumber) {
      checkEpisodeAvailability(seasonNumber, episodeNumber);
    } else {
      toast.error('Please enter both season and episode numbers.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  if (!show || !show.kind.includes('series')) {
    return (
      <>
        {token && (
          <Row className='mt-2'>
            <Col>
              <Button variant="secondary">
                <a href={`https://torrentgalaxy.to/torrents.php?search=tt${show.imdbID}&sort=seeders&order=desc`} target="_blank" rel="noopener noreferrer">Visit Torrent Galaxy</a>
              </Button>
            </Col>
          </Row>
        )}
      </>
    )
  }

  return (
    <div>
      <p className='m-2'>Check Episode Availability</p>


      <Row>
        <Col>
          <Form onSubmit={handleCheckAvailability}>
            <Form.Group>
              <Form.Control
                required
                type="number"
                placeholder="Season Number"
                value={seasonNumber}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value < 1 || value > show['seasons']) {
                    e.target.setCustomValidity(`Season number must be between 1 and ${show['seasons']}`);
                  } else {
                    e.target.setCustomValidity('');
                  }
                  setSeasonNumber(value);
                  setEpisodeNumber(''); // Reset episode number when season changes

                  // Fetch or update the total episodes for the selected season
                  if (value) {
                    setMaxEpisode('')
                  }
                }}
                min="1"
                max={show['seasons']}
              />
            </Form.Group>
            <p></p>
            <Form.Group>
              <Form.Control
                required
                type="number"
                placeholder="Episode Number"
                value={episodeNumber}
                onChange={(e) => {
                  const value = e.target.value;


                  if (maxEpisode && (value < 1 || value > maxEpisode)) {
                    e.target.setCustomValidity(`Episode number must be between 1 and ${maxEpisode}`);
                  } else {
                    e.target.setCustomValidity('');
                  }

                  setEpisodeNumber(value);
                }}
                min="1"
                max={maxEpisode} // Adjust max based on selected season
              />
            </Form.Group>

            <p></p>
            <Button
              variant="secondary"
              disabled={episodeLoad}
              type="submit"
            >
              {episodeLoad ? 'Loading...' : 'Check Availability'}
            </Button>
          </Form>
          <p></p>
          {availabilityMessage && (
            <div>
              {airDate > currentDate && (
                <Button variant="secondary" onClick={() => setShowReminderModal(true)}>
                  <i className="bi bi-bell"></i> Set Reminder
                </Button>
              )}
            </div>
          )}

          <p></p>


          {responseData && (
            <Button
              variant="secondary"
              onClick={toggleView}
              style={{ marginBottom: '10px' }}
            >
              {isInfoVisible ? 'View Episode Promo   🎬' : 'View Episode Info ℹ️'}
            </Button>
          )}



          <p></p>

          {availabilityMessage.includes("Enjoy!!") && token && (
            <div>
              <Button variant="secondary">
                <a href={`https://torrentgalaxy.to/torrents.php?search=tt${show.imdbID}&sort=seeders&order=desc`} target="_blank" rel="noopener noreferrer">Visit Torrent Galaxy</a>
              </Button>
            </div>
          )}
        </Col>


        <Col>
          {responseData && (
            <Col className="episodeInfo">

              {isInfoVisible ? (

                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "100%",
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >

                  <img
                    src={
                      !responseData["image"] || responseData["image"] === " "
                        ? `${placeholderImageUrl}`
                        : `${responseData["image"]}`
                    }
                    alt={responseData["titleText"]}
                    className="img-fluid"
                    style={{
                      cursor: "pointer",
                      maxWidth: "600px",
                      maxHeight: "400px",
                      width: "100%",
                      height: "auto",
                      objectFit: 'cover'
                    }}
                  />
                  {isHovered && (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: "10px",
                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                          padding: "5px",
                          color: "white",
                          zIndex: 2,
                        }}
                      >
                        <p style={{ margin: 0 }}>Title: {responseData["titleText"]}</p>
                        <p style={{ margin: 0 }}>
                          Rating:{" "}
                          {responseData["extra_data"]
                            ? responseData["extra_data"]["episode_rating"]
                            : ""}
                        </p>
                        <p style={{ margin: 0 }}>
                          Caption:{" "}
                          {responseData["extra_data"]
                            ? responseData["extra_data"]["image_caption"]
                            : ""}
                        </p>
                      </div>
                      <div
                        className="plot-info"
                        style={{
                          width: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                          zIndex: 1,
                          padding: "10px",
                          position: "absolute",
                          bottom: "-100px", // Default positioning for larger screens
                          left: "0",
                        }}
                      >
                        <p style={{ color: "white", margin: 0 }}>
                          Plot:{" "}
                          {responseData["extra_data"]
                            ? decodeURIComponent(
                              responseData["extra_data"]["episode_plot"]
                            )
                            : ""}
                        </p>
                      </div>
                    </>
                  )}
                </div>

              ) : (
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header onClick={handleAccordionClick} >

                      <p className='accordion-header' >Watch Episode Promo &#128250;</p>
                    </Accordion.Header>
                    <Accordion.Body>

                      {trailerLoading ? (
                        <Container>
                          <Row className="justify-content-center align-items-center" style={{ height: '100vh' }}>
                            <Col xs="auto">
                              <l-quantum size="150" speed="1.5" color="white"></l-quantum>
                            </Col>
                          </Row>
                        </Container>
                      ) : (
                        videos.map((video) => (
                          <div className="player-wrapper" key={video.id.videoId}>
                            <ReactPlayer
                              className="react-player"
                              url={`https://www.youtube.com/watch?v=${video.id.videoId}`}
                              width="100%"
                              height="100%"
                              controls={true}
                              playing={isAccordionStop}
                            />
                          </div>
                        ))
                      )}
                      {trailerError && <p>Error fetching trailer: {trailerError.message}</p>}
                    </Accordion.Body>

                  </Accordion.Item>
                </Accordion>
              )}

            </Col>



          )}
        </Col>


      </Row>



      {showReminderModal && (
        <Modal show={showReminderModal} onHide={() => setShowReminderModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Set Reminder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Add any content you want inside the modal */}
            <p>Set a reminder on {formattedAirDate}</p>
            <p>For {show.title} Season {seasonNumber} Episode {episodeNumber}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowReminderModal(false)}>
              Close
            </Button>
            <Button variant="secondary" onClick={() => {
              setShowReminderModal(false);
              setReminder();
            }}>
              Save Reminder
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default EpisodeAvailability;
