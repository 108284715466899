import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdUnit from './AdUnit';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (


      <Container className='mt-4'>
        <Row>
          <Col className="text-center">
           <p> <a href='https://kagus-code.web.app/' target='_blank'>
              Developed by Eston &copy; {currentYear}
            </a> | <Link to="/policy"> Privacy</Link></p>
          </Col>
        </Row>

        <Row>

        </Row>

      </Container>



  );
};

export default Footer;
